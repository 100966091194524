<template>
    <div>

        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-9 p-0 col-md-10">
                    <h3 class="mb-0 text-white"><span v-if="this.id !== 'add'">Upravuje sa </span><span v-else>Vytvárate </span><span class="text-lowercase">termín uzatvorenia</span></h3>
                    <h2 class="">{{crange.title}}</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-9 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <a-input alternative=""
                                                        label="Dôvod uzatvorenia"
                                                        input-classes="form-control-alternative"
                                                        v-model="crange.title"
                                            />
                                            <span class="description"> Zobrazuje sa len na administračnej stránke</span>
                                            <div class="mb-4">
                                            <h6 class="heading-small text-muted mb-2">Termín uzatvorenia</h6>
                                            <a-range-picker class="mb-3"  v-model="crange.daterange"  :locale="locale"  @change="onChange" />
                                        </div>

                                            <template>
                                                <div class="mb-4">
                                                    <h6 class="heading-small text-muted mb-2">Uzavrieť typ izby</h6>
                                                    <a-checkbox-group class="rooms" :options="contenttypes" v-model="crange.rooms"/>
                                                </div>
                                            </template>


                                            <template>
                                                <div class="mb-4">
                                                    <h6 class="heading-small text-muted mb-2">Uzavrieť konkrétne izby</h6>
                                                    <a-checkbox-group class="rooms" :options="arooms" v-model="crange.roomspecific"/>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-primary">Uložiť</button>
                                </div>
                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ADatePicker from "ant-design-vue/es/date-picker";
    import moment from 'moment'
    import 'moment/locale/sk';

    Vue.use(FormItem);

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Uzatvorenie od", "Ukončenie"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }


    export default {
        name: 'crange',
        components: {ADatePicker},
        colorsto: JSON.parse(localStorage.colors),
        props: ['id'],
        data() {
            return {
                url: getURL(),
                fieldData: {},
                dateFormat : 'YYYY-MM-DD',
                crange:{'daterange':'','closedfrom':'','closedto':'','title':'','rooms':'','roomspecific':''},
                formData: null,
                locale,
                contenttypes:[],
                arooms:[],
                colorsto: JSON.parse(localStorage.colors),
                ct: null,
                rdateFormat: 'YYYY/MM/DD',
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
                head: authHeader(),
                contenttypeId: {
                    uid: this.id
                },
                options: [],
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [], preserve: true});
        },
        methods: {
                moment,
          backEndDateFormat(date) {
        return moment(date._d).format('YYYY-MM-DD');
    },
            formatDate(dt) {
                return moment(moment.unix(dt),'YYYY/MM/DD')
            },
            onChange(date) {
                this.crange.closedfrom = this.backEndDateFormat(date[0]);
                this.crange.closedto = this.backEndDateFormat(date[1]);
            },
            bool(v) {
                return v === "true" || v === "1";
            },
            handleSubmit() {

                dataService.axiosPost(this.$options.name + "/" + this.id, this.crange).then(results => {
                    if (results.data) {
                        this.$message.success('Obsah bol upravený');
                        if(!isNaN(this.id))
                        {
                            this.$router.push('/crange/'+results.data[0])
                        }
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            handleChange(info,slug) {
                this.fieldData[slug] = null;
                if (info.file.status === 'done') {
                    this.fieldData[slug] = info.file.response[0]
                } else if (info.file.status === 'error') {
                    this.fieldData[slug] = ""
                }
            },
        },
        mounted() {
            if (this.id && this.id !== "add") {
                dataService.axiosFetch("crange", this.id).then(results => {
                    this.crange = results;
                     if(this.crange.rooms)
                        this.crange.rooms = JSON.parse(this.crange.rooms);
                    if(this.crange.roomspecific)
                        this.crange.roomspecific = JSON.parse(this.crange.roomspecific);
                     this.crange.daterange = [moment(results.closedfrom,this.rdateFormat),moment(results.closedto,this.rdateFormat)];
                });

            }
            dataService.axiosFetch("content","typy_izieb").then(results => {
                var roleops = [];
                roleops.push({"value" : '0' , "label" : 'Celý objekt'});
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.contenttypes = roleops;
            });

            dataService.axiosFetch("content","room").then(results => {
                var roleops = [];
                results.reverse();
                roleops.push({"value" : '0' , "label" : 'Nezáleží'});
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.arooms = roleops;
            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    h2.display-2.text-white
    {min-height:65px}

    .rooms >>> .ant-checkbox-group-item
    {width:25%;margin-right:0%;
    text-align:left}
    .description
    {margin-top:-40px;display:inline-crange;font-size:14px;color:rgba(0,0,0,.3);
        margin-bottom:-20px;position:relative;top:-20px;left:5px}
</style>
